/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react'
import { graphql, navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import SEO from '../components/Seo'
import LABG from '../assets/LA-BG.svg'
import SABG from '../assets/SA-BG.svg'

const LandingPage = ({ data: { contentfulBasicPage } }) => {
  // const { metadata } = allContentfulHomePage.nodes[0]
  // const { sections } = allContentfulHomePage.nodes[0]

  const Wrapper = styled.div`
    margin: 0 auto;
    display: flex;
    flex-direction: column-reverse;
    min-height: auto;
    @media (min-width: 1024px) {
      flex-direction: row;
      min-height: calc(100vh - 300px);
    }
    h1 {
      color: #000;
      text-align: center;
      font-family: Poppins;
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: 123%;
      margin: 0;
    }
    p {
      color: #152540;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 143%;
      margin: 0;
    }
    a,
    button {
      padding: 10px 24px;
      font-weight: 700;
      font-size: 16px;
      letter-spacing: 0.25px;
      border-radius: 26px;
      white-space: nowrap;
      cursor: pointer;
      display: inline-block;
      width: auto;
      height: auto;
      line-height: normal !important;
      transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
    }
  `
  const LA = styled.div`
    background-image: url(${LABG});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: none;
    border-top: 1px solid #e1edf7;
    @media (min-width: 1024px) {
      align-items: flex-end;
      border-right: 1px solid #e1edf7;
      border-top: none;
      p {
        max-width: 340px;
        text-align: right;
      }
    }
    button {
      color: #5a55ab;
      background-color: transparent;
      border: 1px solid #5a55ab;

      &:hover {
        color: #fff;
        background-color: #5a55ab;
        border-color: #5a55ab;
      }
    }
    a {
      color: #fff;
      background-color: #5a55ab;
      border: 1px solid #5a55ab;

      &:hover {
        color: #5a55ab;
        background-color: #fff;
        border-color: #5a55ab;
      }
    }
  `
  const SA = styled.div`
    background-image: url(${SABG});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 1024px) {
      align-items: flex-start;
      p {
        max-width: 253px;
        text-align: left;
      }
    }
    a {
      color: #5a55ab;
      background-color: transparent;
      border: 1px solid #5a55ab;

      &:hover {
        color: #fff;
        background-color: #5a55ab;
        border-color: #5a55ab;
      }
    }
    a:first-child {
      color: #fff;
      background-color: #8745ef;
      border: 1px solid #8745ef;

      &:hover {
        color: #8745ef;
        background-color: #fff;
        border-color: #8745ef;
      }
    }
  `
  const LAwidthWrapper = styled.div`
    max-width: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    padding: 50px 1rem;
    width: 100%;
    @media (min-width: 1024px) {
      align-items: flex-end;
      max-width: 700px;
      padding: 43px 35px 50px 20px;
    }
  `
  const SAwidthWrapper = styled.div`
    max-width: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 24px;
    padding: 50px 1rem;
    width: 100%;
    @media (min-width: 1024px) {
      align-items: flex-start;
      max-width: 700px;
      padding: 43px 20px 50px 35px;
    }
  `
  const ButtonsWrapper = styled.div.attrs(() => ({
    className: 'buttons-wrapper',
  }))`
    display: flex;
    align-items: center;
    gap: 16px;
  `
  const HeroImage = styled.div`
    display: flex;
    ${'' /* flex-basis: 44.5%; */}
    border-radius: 16px;
    ${'' /* justify-content: center; */}
    margin-inline: 24px;
    margin-top: 0px;
    box-shadow: 0px 4px 4px 0px rgba(56, 37, 96, 0.05),
      1px 3px 30px 0px rgba(56, 37, 96, 0.2);
    div[data-placeholder-image] {
      opacity: 0 !important;
    }
    img[data-main-image] {
      opacity: 1 !important;
    }
    @media (min-width: 1024px) {
      margin-top: 49px;
      margin-inline: auto;
    }
  `
  const HeroImageLA = styled.div`
    display: flex;
    padding-top: 0px;
    box-shadow: 0px 4px 4px 0px rgba(56, 37, 96, 0.05),
      1px 3px 30px 0px rgba(56, 37, 96, 0.2);
    border-radius: 16px;
    margin-inline: 24px;
    div[data-placeholder-image] {
      opacity: 0 !important;
    }
    img[data-main-image] {
      opacity: 1 !important;
    }
    @media (min-width: 1024px) {
      margin-top: 49px;
      margin-inline: auto;
    }
  `

  const handleLearningClick = () => {
    navigate('/learning')
  }
  return (
    <>
      <SEO
        title={contentfulBasicPage?.metadataTitle}
        description={contentfulBasicPage?.metadataDescLong.internal.content}
        meta={[
          {
            property: 'og:image:width',
            content: '561',
          },
          {
            property: 'og:image:height',
            content: '268',
          },
          {
            property: 'og:image:alt',
            content: 'description_of_site',
          },
          {
            property: 'og:image',
            content: 'https://innential.com/metaimg.png',
          },
          {
            property: 'og:url',
            content: 'https://innential.com',
          },
        ]}
      />
      <Wrapper>
        <LA>
          <LAwidthWrapper>
            <h1>{contentfulBasicPage.title}</h1>
            <p>{contentfulBasicPage.underTitle}</p>
            <ButtonsWrapper>
              <a href={contentfulBasicPage.ctaLink}>
                {contentfulBasicPage.ctaLinkText}
              </a>
              <button type="button" onClick={handleLearningClick}>
                {contentfulBasicPage.pageLinkText}
              </button>
            </ButtonsWrapper>
            <HeroImageLA>
              <GatsbyImage
                image={contentfulBasicPage?.picture?.gatsbyImageData}
                alt="Learning assistant image"
                // style={{ maxWidth: `588px` }}
              />
            </HeroImageLA>
          </LAwidthWrapper>
        </LA>
        <SA>
          <SAwidthWrapper>
            <h1>{contentfulBasicPage.titleAi}</h1>
            <p>{contentfulBasicPage.underTitleAi}</p>
            <ButtonsWrapper>
              <a href={contentfulBasicPage.ctaLinkAi}>
                {contentfulBasicPage.ctaLinkAiText}
              </a>
              <a href={contentfulBasicPage.pageLinkAi}>
                {contentfulBasicPage.pageLinkAiText}
              </a>
            </ButtonsWrapper>
            <HeroImage>
              <GatsbyImage
                image={contentfulBasicPage?.pictureAi?.gatsbyImageData}
                alt="Service assistant image"
                // style={{ maxWidth: `296px` }}
              />
            </HeroImage>
          </SAwidthWrapper>
        </SA>
      </Wrapper>
    </>
  )
}

export default LandingPage

export const query = graphql`
  {
    contentfulBasicPage(id: { eq: "d736fb78-5652-5a1d-89a5-dab1a0417c31" }) {
      title
      titleAi
      underTitle
      underTitleAi
      ctaLink
      ctaLinkAi
      pageLink
      pageLinkAi
      picture {
        file {
          url
        }
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
      pictureAi {
        file {
          url
        }
        gatsbyImageData(width: 800, quality: 100, layout: CONSTRAINED)
      }
      pageLinkAiText
      pageLinkText
      ctaLinkAiText
      ctaLinkText
      metadataTitle
      metadataDescLong {
        internal {
          content
        }
      }
    }
  }
`
